export default {
  namespaced: true,
  state: {
    historyListData: null,
    scheduledSerial: -1,
  },
  getters: {
  },
  mutations: {
    setHistoryListData(state, data) {
      state.historyListData = data;
    },
    setScheduledSerial(state, data) {
      state.scheduledSerial = data;
    },
    removeFromHistoryListData(state, serial) {
      for (let i=0;i<state.historyListData.length;i++) {
        if (state.historyListData[i].serial === serial) {
          state.historyListData.splice(i, 1);
          return;
        }
      }
    }
  },
  actions: {
    async refreshHistoryList({ commit, dispatch }) {
      const busyName = 'eventRefreshHistoryList';
      await dispatch('appendComponentBusy', busyName, { root: true });
      try {
        const res = await dispatch('api/getReviewOrderListPromise', null, { root: true });
        commit('setHistoryListData', res.list);
      } catch (error) {
        await dispatch('appendErrorMsg', error.toString(), { root: true });
        await dispatch('showMsgModal', error.toString(), { root: true });
      } finally {
        await dispatch('clearComponentBusy', busyName, { root: true });
      }
    },
    clearHistoryListData({ commit }) {
      commit('setHistoryListData', null);
    },
    removeFromHistoryListData({ commit }, orderSerial) {
      commit('removeFromHistoryListData', orderSerial);
    },
    setScheduledSerial({ commit }, serial) {
      commit('setScheduledSerial', serial);
    },
    clearScheduledSerial({ commit }) {
      commit('setScheduledSerial', -1);
    },
  }

}
